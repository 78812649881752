import mutations from './mutations';
import Cookies from 'js-cookie';
import { login, getMenu,} from '@/api/authentication'
import * as config from '@/utils/config.ts';

const state = () => ({
    login: !!Cookies.get('logedIn_c'),
    loading: false,
    error: null,
    menuData: [],
    website_menu_data: [],
    site_lang:'en'
});


const actions = {
    
    async login({ commit }, params) {
        try {
            commit('loginBegin');
            
            login(params)
            .then(rs => {
                if (config.API_RES_CODE_SUC.includes(rs.code))
                {
                    Cookies.set('logedIn_c', true);
                    
                    Cookies.set('app_type', rs.data.app_type);
                    Cookies.set('expires_time', rs.data.expires_time);
                    Cookies.set('token', rs.data.token);
                    /*Cookies.set('userinfo', rs.data.userinfo);*/
                    Cookies.set('userinfo', JSON.stringify(rs.data.userinfo));
                    
                    return commit('loginSuccess', true);
                } else {
                    commit('loginErr', '');
                }
            })
            .catch(error => {
                console.error("An error occurred:", error);
                
                commit('loginErr', '');
            });
            
        } catch (err) {
            commit('loginErr', err);
        }
    },
    async logOut({ commit }) {
        try {
            commit('logoutBegin');
            Cookies.remove('logedIn_c');
            commit('logoutSuccess', null);
        } catch (err) {
            commit('logoutErr', err);
        }
    },
    
    async getMenu({ commit, dispatch }, params) {
        return getMenu(params)
        .then(rs => {
            
            if (config.API_RES_CODE_SUC.includes(rs.code))
            {
                let menu_data = rs.data;

                
                menu_data.forEach(v => {
                    /*有children 但不展示 为 菜单 为按钮 把chidren 删除并复制
                        用于解决 显示子级页面 会先显示 父级页面  的问题*/
                    if (v.route_path != '' && v.children && v.children.length > 0)
                    {
                        v.children.forEach(child_v => {
                            menu_data.push(child_v);
                        })
                        
                        v.children = [];
                    }
                })
                
                const web_arr = [];
                
                menu_data.forEach(vv => {
                    
                    if (vv.pid == config.WEBSITE_MENU_PID && vv.menu_type == 1) web_arr.push(vv);
                })

                commit('menuData', menu_data);
                // 修改记录
                
                commit('websiteMenuData', web_arr);
                
            } else {
                dispatch('logOut')
            }
            
        }).catch(() => {
            dispatch('logOut')
        })
    },
    setItemLang({ commit }, lang) {  
        commit('someMutation', lang);  
    }  
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
