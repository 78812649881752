export default {
  loginBegin(state) {
    state.loading = true;
  },
  loginSuccess(state, data) {
    state.loading = false;
    state.login = data;
  },
  
  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  
  logoutBegin(state) {
    state.loading = true;
  },
  
  logoutSuccess(state, data) {
    state.loading = false;
    state.login = data;
  },
  
  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  
  menuData(state,data)
  {
    state.menuData = data;
  },
  
  websiteMenuData(state,data)
  {
    state.website_menu_data = data;
  },
  someMutation(state, lang) {  
    state.site_lang = lang;  
  }  
};
